
import {Component, Vue, Prop } from 'vue-property-decorator';
import PictureInput from "@/components/VuePictureInput.vue";
import { mixins } from 'vue-class-component';
import VModel from '@/mixins/VModel.vue';

@Component({
    name: "PicturePreview",
    components: {
        PictureInput
    }
})
export default class PicturePreview extends mixins(VModel) {
    @Prop({default: 200}) width!: string;
    @Prop({default: 200}) height!: string;
    @Prop() label!: string;
    @Prop() centeredPicture!: boolean;
    @Prop({default: false}) readonly!:boolean;

    $refs:any = {
        fileInput: HTMLElement
    }
    data: any = {};


    dataUrl: string = '';

    // @ts-ignore;
    apiUrl:string = <string>process.env.VUE_APP_API_URL.replace('/1.0', '') + "/";

    get imageType() {
        return 'image/*';
    }

    get imgSource() {
        return this.innerValue;
    }

    get imageDataUrl() {
        return this.dataUrl;
    }

    isBase64() {
        return this.innerValue.startsWith('data:');
    }

    isRemoteUrl() {
        return this.innerValue.startsWith('https://');
    }

    isServerUrl() {
        return this.innerValue.startsWith(process.env.VUE_APP_API_URL) || this.innerValue.startsWith('static');
    }

    get left() {
        return +(this.width || 0) + 15 + 'px';
    }

    get fill() {
        if(!this.innerValue) return '';
        if (this.isBase64() || this.isRemoteUrl()) return this.innerValue;
        if(this.isServerUrl()) return this.apiUrl + this.innerValue;
    }

    get inputFileStrings() {
        return {
            upload: this.label || this.$t('pictureInput.upload'), // HTML allowed
            drag: this.label || this.$t('pictureInput.drag'), // HTML allowed
            tap: this.label || this.$t('pictureInput.tap'), // HTML allowed
            change: this.$t('pictureInput.change'), // Text only
            remove: this.$t('pictureInput.remove'), // Text only
            select: this.$t('pictureInput.select'), // Text only
            selected: this.$t('pictureInput.selected'), // HTML allowed
            fileSize: this.$t('pictureInput.fileSize'), // Text only
            fileType: this.$t('pictureInput.fileType'), // Text only
            aspect: this.$t('pictureInput.aspect') // Text only
        }
    }

    edit() {
        // @ts-ignore;
        this.$refs.pictureInput.$el.querySelector('.btn:not(.btn-secondary)').click();
    }

    remove() {
        // @ts-ignore;
        this.$refs.pictureInput.$el.querySelector('.btn.btn-secondary').click();
    }

    onFileChange(e: any) {
        const files = e.target.files;

        if (!files.length) return;

        const file = files[0];
        const  { name, size, lastModified, type } = file;

        console.log("here");
        if (type.substr(0, 6) !== 'image/') return;
        const reader = new FileReader();
        reader.onload = (e:any) => {
            this.dataUrl = e.target.result;
            this.$emit('input', e.target.result);
        }
        reader.readAsDataURL(file);
    }

    onChange(image:string) {
        // file
        this.data.base64 = image;
        this.$emit('input', image);
    }

    onRemove() {
        this.data.base64 = "";
        this.dataUrl = ''
        this.innerValue = null;
        this.$emit('input', undefined);
    }

    onSelect() {
        this.$refs.fileInput.click();
    }
}
